<template>
  <div class="container" id="footer">
    <div class="footer">
      <el-row>
        <el-col :span="18">
          <div class="content">
            <div class="colConetent">
              <div class="title">服务与支持</div>
              <div class="text">法律与安全</div>
              <div class="text">合规与举报</div>
              <div class="text">用户协议</div>
              <div class="text">隐私政策</div>
              <div class="text">加入我们</div>
              <div class="text">联系我们</div>
            </div>
            <div class="colConetent">
              <div class="title">绿链产品</div>
              <div class="text">绿链四流数据管理系统</div>
              <div class="text">绿链实时物流小程序</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="code">
            <div class="codeConetent">
              <div class="title">技术咨询</div>
              <div class="QRcode">
                <img src="@/assets/code/code3.png" style="width: 150px;height: 150px">
              </div>
            </div>
            <div class="codeConetent">
              <div class="title">客服咨询</div>
              <div class="QRcode">
                <img src="@/assets/code/code3.png" style="width: 150px;height: 150px">
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <row>
        <col :span="24">
        <div class="copyright">
          <img src="@/assets/logo/logo2.png" style="width: 106px;height: 46px;">
          <div style="color: #8B8B8B;font-size: 14px;margin-left: 40px;">
            <!-- <span> 客服电话: 400-686-0900 ｜ 技术咨询: press@lixiang.com ｜ 合作邮箱: compliance@lixiang.com</span> -->
            <span style="margin-left: 20px;"> ©2024绿链恒生.com 版权所有 渝ICP备2023009963号-1</span>
          </div>
        </div>
        </col>
      </row>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
onMounted(() => {
  setTimeout(() => {
    const element = document.getElementById("footer");
    element.style.display = "block";
  }, "1000");

})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 500px;
  background-color: #011C1B;
  position: relative;
  z-index: 10;
  display: none;

  .footer {
    width: 1920px;
    height: 100%;
    margin: 0 auto;
    padding: 92px 200px 37px 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
      display: flex;
      position: relative;

      .colConetent {
        display: flex;
        flex-direction: column;
        margin-right: 126px;

        .title {
          color: #FFFFFF;
          font-size: 16px;
          margin-bottom: 10px
        }

        .text {
          color: #8B8B8B;
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }

    .content::before {
      content: "";
      display: block;
      width: 1px;
      height: 210px;
      background-color: #464646;
      position: absolute;
      top: 65%;
      right: 100px;
      transform: translateY(-50%);
    }

    .code {
      display: flex;

      .codeConetent {
        display: flex;
        flex-direction: column;
        margin-right: 70px;

        .title {
          color: #FFFFFF;
          font-size: 16px;
          margin-bottom: 10px
        }
      }
    }

    .copyright {
      display: flex;
      align-items: center;
    }
  }
}

.container::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #464646;
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
}
</style>