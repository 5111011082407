<template>
  <div class="container">
    <div class="elevator" id="elevator">
      <div class="exitPhoneHover">
        <img class="exitPhone" src="@/assets/icon/phone.png" style="width:60px;height:60px;" />
        <img class="exitPhone2" src="@/assets/icon/phoneA.png" style="width:60px;height:60px;" />
      </div>
      <div class="exitTopHover">
        <img class="exitTop" @click="handleScrollTop" src="@/assets/icon/top.png" style="width:60px;height:60px;" />
        <img class="exitTop2" @click="handleScrollTop" src="@/assets/icon/topA.png" style="width:60px;height:60px;" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue'
onMounted(() => {
  window.addEventListener('scroll', handleShowElevator);
})

const navHeight = ref(null);
//监听滚动高度
const handleShowElevator = () => {
  navHeight.value = window.scrollY;
  if (navHeight > 500) {
    document.getElementById("elevator").style.display = "flex";
  }
}
const handleScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
</script>

<style lang="scss" scoped>
.container {
  .elevator {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 30px;
    top: 80%;
    z-index: 99;

    .exitPhoneHover .exitPhone {
      display: inline-block;
      cursor: pointer;
    }

    .exitPhoneHover .exitPhone2 {
      display: none;
      cursor: pointer;
    }

    .exitPhoneHover:hover .exitPhone {
      display: none;
      cursor: pointer;
    }

    .exitPhoneHover:hover .exitPhone2 {
      display: inline-block;
      cursor: pointer;
    }

    .exitTopHover {
      margin-top: 20px;
    }

    .exitTopHover .exitTop {
      display: inline-block;
      cursor: pointer;
    }

    .exitTopHover .exitTop2 {
      display: none;
      cursor: pointer;
    }

    .exitTopHover:hover .exitTop {
      display: none;
      cursor: pointer;
    }

    .exitTopHover:hover .exitTop2 {
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>
