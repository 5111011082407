import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
//引入动画
import "animate.css";
import WOW from "wow.js";
import { createHead } from "@unhead/vue";
// 初始化 WOW.js
const wow = new WOW();
wow.init();

//seo unhead
const head = createHead();

createApp(App).use(store).use(router).use(ElementPlus).use(head).mount("#app");
