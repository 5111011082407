<template>

  <router-view />
</template>

<script setup>

</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  max-width: 1920px;

}
</style>